export class VideoUsage {
    constructor(id, totalUsage, totalViews, viewsPrecent) {
      this.Id = id;
      this.TotalUsage = totalUsage;
      this.TotalViews = totalViews;
      this.ViewsPrecent = viewsPrecent;    
    }
};

export class UsareUsageResponsEntity {
    constructor() {
      this.VideoUsage = [];
      this.isComninedWithStorageInfo = false;
      this.TotalUsage = 0;
      this.TotalPrice = 0;
    }
};

const GetJson = (json) => {
    if (json === null || json === undefined)
    {
        return null;
    }

    if (typeof json === 'string')
    {
        // we got here a json string
        return JSON.parse(json.data);
    }

    return json;
  }

export const parseJsonOfUsareUsageResponsEntity = (json) => {
    if (Array.isArray(json) && json.length === 0)
    {
      return new UsareUsageResponsEntity();
    }

    const obj = GetJson(json);
    if (obj === null)
    {
        return null;
    }

    const result = new UsareUsageResponsEntity();

    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed in JavaScript, so we add 1 to get the correct month
    const currentMonth = `${year}_${month}`;

    for (const [key, value] of Object.entries(obj.monthlyUsage)) {
        if (key !== currentMonth)
        {
            continue;
        }
        result.TotalUsage = value.totalUsage;
        result.TotalPrice = value.totalPrice;
      for (const videoUsage of value.videoUsage) {
        const video = new VideoUsage(
          videoUsage.id,
          videoUsage.totalUsage,
          videoUsage.totalViews,
          videoUsage.viewsPrecent
        );
        result.VideoUsage.push(video);
      }
    }
    return result;
  }