import { Cache } from "../../../utils/cache/index";
import { IFetcherFramework } from "../entities/IFetcherFramework";
import { videoStorageFramework } from "./videoStorageFramework";
import { videoUsageFramework } from "./videoUsageFramework";
import { GetCombinedUsageStorage } from "../entities/VideoStorageAndUsage";
import { parseJsonOfUsareUsageResponsEntity } from "../entities/VideoUsage";
import { parseJsonOfUsareStorage } from "../entities/VideoInformation";
import { UsareUsageAndStorageEntity } from "../entities/VideoStorageAndUsage";

// For being ble to call store from here
import { SetVideoUsageStorage } from "../../../store/store";
import isNullOrUndefined from "../../../utils/isNullOrUndefined";

const CACHE_TIMEOUT = 60 + 10;
const REVOKE_AFTER_TIMEOUT = true;
const VARIABLE_NAME = "raw_server_storage_usage_resp";

const cache = new Cache(CACHE_TIMEOUT, REVOKE_AFTER_TIMEOUT, VARIABLE_NAME);

var instance = null;

const CombineUsageAndStorageToONeObject = (usage, storage) =>
{
    const usareUsageResponsEntity = parseJsonOfUsareUsageResponsEntity(usage.data);
    const [userStorageList, totalStorageBytes] = parseJsonOfUsareStorage(storage.data);
    return GetCombinedUsageStorage(usareUsageResponsEntity, userStorageList, totalStorageBytes);
}

export class videoStorageAndUSageFramework
{
    constructor(token) {
        if (instance !== null)
        {
            // Update token if requested
            instance.storageFw.implementor.token = token;
            instance.usageFw.implementor.token = token;
            return instance;
        }

        const storage = new videoStorageFramework(token);
        const usage = new videoUsageFramework(token);
        this.storageFw = new IFetcherFramework(storage);
        this.usageFw = new IFetcherFramework(usage);

        // save ourself
        instance = this;
    }

    DeleteCache()
    {
        cache.delete();
    }

    GetFromCache()
    {
        if (cache.state === null)
        {
            return cache.load();
        }
        
        return cache.state;
    }

    async GetStorage()
    {
        return await this.storageFw.FetchFromServer();
    }

    async GetUsage()
    {
        return await this.usageFw.FetchFromServer();
    }

    async FetchFromServer(setNew)
    {
        if (isNullOrUndefined(this.storageFw.implementor.token) === true || isNullOrUndefined(this.usageFw.implementor.token))
        {
            return null;
        }

        const cacheResp = this.GetFromCache();
        if (cacheResp !== null)
        {
            // Set the state with new data
            setNew(SetVideoUsageStorage(JSON.stringify(cacheResp)));
            return;
        }

        // if we got here - we need to fetch it from server
        const storageResp = await this.GetStorage();
        const usageResp = await this.GetUsage();

        // if we dont have ens.
        if (storageResp.statusCode !== 200 && storageResp.statusCode !== 404)
        {
            return null;
        }

        // usageResp return 404 if there is no data of usage yet
        if (usageResp.statusCode !== 200 && usageResp.statusCode !== 404)
        {
            return null;
        }

        // Try combine them
        const comnbinationRes = CombineUsageAndStorageToONeObject(usageResp, storageResp);
        if (comnbinationRes === null)
        {
            return null;
        }

        // if response valid - save in cache
        cache.save(comnbinationRes);

        // Set the state with new data
        setNew(SetVideoUsageStorage(JSON.stringify(comnbinationRes)));
    }

    _AddVideoList(videoStorageAndUsage, data, setNew)
    {
        //console.log("[framework::_AddVideoList] start");
        if (videoStorageAndUsage === null || videoStorageAndUsage === undefined)
        {
            return null;
        }

        // case we have no data - adding new
        if (data === null || data === undefined)
        {
            var newData = null;
            videoStorageAndUsage.forEach((vid, index) => {
                if (index === 0)
                {
                    newData = this.AddVideoToEmptyUsareUsageAndStorageEntity(videoStorageAndUsage);
                    return;
                }

                newData.VideoList.push(vid);
            });

            //console.log("[framework::_AddVideoList] before cache save 1");
            cache.save(newData);

            // Set the state with new data
            //console.log("[framework::_AddVideoList] before set new 1");
            setNew(SetVideoUsageStorage(JSON.stringify(newData)));
            //console.log("[framework::_AddVideoList] after set new 1");
            return;
        }

        // data.VideoList => existed
        // videoStorageAndUsage requested
        // filteredList all requested that don't exists in data.VideoList
        var filteredList = videoStorageAndUsage.filter( v => data.VideoList.every(exists => exists.Id !== v.Id ));
        if (filteredList.length === 0)
        {
            return;
        }

        // case we have data - just add the new data
        const newCopyData = JSON.parse(JSON.stringify(data));
        filteredList.forEach(vid => {
            newCopyData.VideoList.push(vid);
        });

        //console.log("[framework::_AddVideoList] before set cache 2");
        cache.save(newCopyData);

        // Set the state with new data
        //console.log("[framework::_AddVideoList] before set new 2");
        setNew(SetVideoUsageStorage(JSON.stringify(newCopyData)));
        //console.log("[framework::_AddVideoList] after set new 2");
        return;
    }

    // Here we alwise expects a VideoStorageAndUsage object
    // data = the current data object from the state
    AddVideo(videoStorageAndUsageParam, data, setNew)
    {
        //console.log("[framework::AddVideo] start");
        const videoStorageAndUsage = JSON.parse(JSON.stringify(videoStorageAndUsageParam));
        if (videoStorageAndUsage === null || videoStorageAndUsage === undefined)
        {
            return null;
        }

        if (Array.isArray(videoStorageAndUsage))
        {
            return this._AddVideoList(videoStorageAndUsage, data, setNew);
        }

        // We are the first video
        if (data === null)
        { 
            data = this.AddVideoToEmptyUsareUsageAndStorageEntity(videoStorageAndUsage);
            cache.save(data);

            // Set the state with new data
            setNew(SetVideoUsageStorage(JSON.stringify(data)));
            return;
        }

        var isVideoExists = false;
        if (data !== null && data !== undefined)
        {
            data.VideoList.forEach( vid => {
                if (isVideoExists === true)
                {
                    return;
                }

                if (vid.Id === videoStorageAndUsage.Id)
                {
                    isVideoExists = true;
                    return; 
                }
            });
        }

        // We don't add already existing video
        if (isVideoExists === true)
        {
            return null;
        }

        const newVidList = JSON.parse(JSON.stringify(data));
        newVidList.VideoList.push(videoStorageAndUsage);
        cache.save(newVidList);
        
        // Set the state with new data
        setNew(SetVideoUsageStorage(JSON.stringify(newVidList)));
    }

    AddVideoToEmptyUsareUsageAndStorageEntity(videoStorageAndUsage)
    {
        return new UsareUsageAndStorageEntity([videoStorageAndUsage], 0, videoStorageAndUsage.Size, videoStorageAndUsage.TotalUsage);
    }

    // Here we alwise expects a VideoStorageAndUsage object
    // data = the current data object from the state
    RemoveVideo(videoStorageAndUsage, data, setNew)
    {
        if (data === null || data === undefined)
        {
            return null;
        }

        // This will filter out the data if exist such a video
        const filteredData = data.VideoList.filter(obj => obj.Id !== videoStorageAndUsage.Id);
        if (filteredData.length === data.VideoList.length)
        {
            // nothing change - there is no such video file
            return null;
        }

        const localCopy = JSON.parse(JSON.stringify(data));
        localCopy.VideoList = filteredData;
        cache.save(localCopy);

        // Set the state with new data
        setNew(SetVideoUsageStorage(JSON.stringify(localCopy)));
    }

    RemoveVideoList(videoStorageAndUsageList, data, setNew)
    {
        if (data === null || 
            data === undefined || 
            videoStorageAndUsageList === null || 
            videoStorageAndUsageList === undefined || 
            videoStorageAndUsageList.length === 0)
        {
            return null;
        }

        // This will filter out the data if exist such a video
        const filteredData = data.VideoList.filter(obj => 
            videoStorageAndUsageList.every( videoStorageAndUsage =>  obj.Id !== videoStorageAndUsage.Id)
        );

        if (filteredData.length === data.VideoList.length)
        {
            // nothing change - there is no such video file
            return null;
        }

        // filteredData contains a list that dont contains anything from videoStorageAndUsageList 
        const localCopy = JSON.parse(JSON.stringify(data));
        localCopy.VideoList = filteredData;
        cache.save(localCopy);

        // Set the state with new data
        setNew(SetVideoUsageStorage(JSON.stringify(localCopy)));
    }

    // data = the current data object from the state
    ChangeVideoStatus(videoStorageAndUsage, data, setNew)
    {
        var isVideoExists = false;
        if (data === null || data === undefined)
        {
            return null;
        }

        const localCopy = JSON.parse(JSON.stringify(data));
        localCopy.VideoList.forEach( vid => {
            if (isVideoExists === true)
            {
                return;
            }

            if (vid.Id === videoStorageAndUsage.Id)
            {
                isVideoExists = true;

                // change the status
                vid.Status = videoStorageAndUsage.Status;
                return; 
            }
        });

        // If video not exists - return
        if (isVideoExists === true)
        {
            return null;
        }

        cache.save(localCopy);
        
        // Set the state with new data
        setNew(SetVideoUsageStorage(JSON.stringify(localCopy)));
    }
};