const getMinuteDifference = (timeA, timeB) =>
{
    var diffMs = (timeA - timeB);
    if (diffMs < 0)
    {
        diffMs = (timeB - timeA);
    }

    return diffMs/60000; // minutes
}

export default getMinuteDifference;