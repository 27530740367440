export class VideoStorageAndUsage {
    constructor(id, totalUsage, totalViews, viewsPrecent, fileName, masterLink, status, size, statusUpdateDate, uploadDate, isUserNotified) {
        this.Id = id;
        this.TotalUsage = totalUsage;
        this.TotalViews = totalViews;
        this.ViewsPrecent = viewsPrecent;
        this.FileName = fileName;
        this.MasterLink = masterLink;
        this.Status = status;
        this.Size = size;
        this.StatusUpdateDate = statusUpdateDate;
        this.UploadDate = uploadDate;
        this.IsUserNotified = isUserNotified;  
    }
};

export class UsareUsageAndStorageEntity {
    constructor(vidList, totalPrice, totalStorage, totalUsage) {
      this.VideoList = vidList;
      this.TotalUsage = totalUsage;
      this.TotalStorage = totalStorage;
      this.TotalPrice = totalPrice;
    }
};

export function AreUsareUsageAndStorageEntityEqual(obj1, obj2) {
    const is1Undef = obj1 === null || obj1 === undefined;
    const is2Undef = obj2 === null || obj2 === undefined;
    if (is1Undef !== is2Undef)
    {
        return false;
    }

    if (obj1.TotalPrice !== obj2.TotalPrice) {
      return false;
    }
    if (obj1.TotalUsage !== obj2.TotalUsage) {
      return false;
    }
    if (obj1.TotalStorage !== obj2.TotalStorage) {
      return false;
    }
    if (obj1.VideoList.length !== obj2.VideoList.length) {
      return false;
    }
    for (let i = 0; i < obj1.VideoList.length; i++) {
      const vid1 = obj1.VideoList[i];
      const vid2 = obj2.VideoList[i];
      if (vid1.Id !== vid2.Id) {
        return false;
      }
      if (vid1.TotalUsage !== vid2.TotalUsage) {
        return false;
      }
      if (vid1.TotalViews !== vid2.TotalViews) {
        return false;
      }
      if (vid1.ViewsPrecent !== vid2.ViewsPrecent) {
        return false;
      }
      if (vid1.FileName !== vid2.FileName) {
        return false;
      }
      if (vid1.MasterLink !== vid2.MasterLink) {
        return false;
      }
      if (vid1.Status !== vid2.Status) {
        return false;
      }
      if (vid1.Size !== vid2.Size) {
        return false;
      }
      if (vid1.StatusUpdateDate !== vid2.StatusUpdateDate) {
        return false;
      }
      if (vid1.UploadDate !== vid2.UploadDate) {
        return false;
      }
      if (vid1.IsUserNotified !== vid2.IsUserNotified) {
        return false;
      }
    }
    return true;
  }

// usage going to be of type UsareUsageResponsEntity
// storage is a list of VideoInformation
export const GetCombinedUsageStorage = (usage, storage, totalStorageBytes) =>
{
    // Do we have something to combine?
    const hasUsage = usage !== null && usage !== undefined;
    const hasStorage = storage !== null && storage !== undefined;
    
    // We have nothing tp do here
    if (hasUsage === hasStorage && hasStorage === false)
    {
        return null;
    }

    // we don't need to combine nothing
    if (hasUsage !== hasStorage)
    {
        if (hasUsage)
        {
            return UsageToUsareUsageAndStorageEntity(usage);
        }

        if (hasStorage)
        {
            return StorageToUsareUsageAndStorageEntity(storage, totalStorageBytes);
        }
    }
    
    // Get the usage
    const resp = CombineUsareUsageAndStorageEntity(UsageToUsareUsageAndStorageEntity(usage), StorageToUsareUsageAndStorageEntity(storage, totalStorageBytes));
    return resp;
};

const IsNullOrUndefined = (obj) =>
{
    if (obj === null)
    {
        return true;
    }

    return obj === undefined;
}

const getIdFromString = (masterLink) => {
    // Split the string into an array using '/' as a delimiter
    const arr = masterLink.split('/');
    return arr[2];
  }

const CombineUsareUsageAndStorageEntity = (usage, storage) => {
    const resp = new UsareUsageAndStorageEntity([], 0, storage.TotalStorage, usage.TotalUsage);
    resp.TotalPrice = usage.TotalPrice + storage.TotalPrice;

    storage.VideoList.forEach(storageObj =>
    {
        var matchedObj = null;
        const key1 = storageObj.Id; // GetKeyFromStorgeElement(storageObj.MasterLink);
        usage.VideoList.forEach(usageObj => {
            if (matchedObj !== null)
            {
                return;
            }

            const key2 = usageObj.Id //GetKeyFromUsageElement(usageObj.Id);
            if (key1 === key2)
            {
                matchedObj = usageObj;
                return;
            }
        });

        // If we dont found a much
        if (matchedObj === null)
        {
            resp.VideoList.push(storageObj);
            return;
        }

        // combine both of them
        resp.VideoList.push(new VideoStorageAndUsage(
        IsNullOrUndefined(matchedObj.Id) ? getIdFromString(storageObj.MasterLink) : matchedObj.Id,
        matchedObj.TotalUsage,
        matchedObj.TotalViews,
        matchedObj.ViewsPrecent,
        storageObj.FileName,
        storageObj.MasterLink,
        storageObj.Status,
        storageObj.Size,
        storageObj.StatusUpdateDate,
        storageObj.UploadDate,
        storageObj.IsUserNotified
        ));
    });

    return resp;
}

const StorageToUsareUsageAndStorageEntity = (storage, totalStorageBytes) =>
{
    const listOfVideoStorageAndUsage = createVideoStorageAndUsageListFromStorage(storage);

    // Add here calculations for total storage
    var storageBytes = totalStorageBytes === null ? 0 : totalStorageBytes;
    /*listOfVideoStorageAndUsage.forEach(element => {
        storageBytes += element.Size;
    })*/

    return new UsareUsageAndStorageEntity(listOfVideoStorageAndUsage, 0, storageBytes , 0); // Currently we don't have here storage price
}

const UsageToUsareUsageAndStorageEntity = (usage) =>
{
    const listOfVideoStorageAndUsage = createVideoStorageAndUsageListFromUsage(usage.VideoUsage);
    return new UsareUsageAndStorageEntity(listOfVideoStorageAndUsage, usage.TotalPrice, 0, usage.TotalUsage);
}

function createVideoStorageAndUsageListFromUsage(videoUsageList) {
    const videoStorageAndUsageList = [];
  
    videoUsageList.forEach(videoUsage => {
      const { Id, TotalUsage, TotalViews, ViewsPrecent } = videoUsage;
  
      const videoStorageAndUsage = new VideoStorageAndUsage(
        getIdFromString(Id),
        TotalUsage,
        TotalViews,
        ViewsPrecent,
        null,
        null,
        null,
        null,
        null,
        null,
        null
      );
  
      videoStorageAndUsageList.push(videoStorageAndUsage);
    });
  
    return videoStorageAndUsageList;
  }


function createVideoStorageAndUsageListFromStorage(videoInfoList) {
    const videoStorageAndUsageList = [];
  
    videoInfoList.forEach(videoInfo => {
      const { fileName, masterLink, status, size, statusUpdateDate, uploadDate, isUserNotified } = videoInfo;
  
      const videoStorageAndUsage = new VideoStorageAndUsage(
        getIdFromString(masterLink),
        0,
        0,
        0,
        fileName,
        masterLink,
        status,
        size,
        statusUpdateDate,
        uploadDate,
        isUserNotified
      );
  
      videoStorageAndUsageList.push(videoStorageAndUsage);
    });
  
    return videoStorageAndUsageList;
  }