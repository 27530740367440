exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-content-js": () => import("./../../../src/pages/content.js" /* webpackChunkName: "component---src-pages-content-js" */),
  "component---src-pages-dashboard-js": () => import("./../../../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-metrics-js": () => import("./../../../src/pages/metrics.js" /* webpackChunkName: "component---src-pages-metrics-js" */),
  "component---src-pages-settings-js": () => import("./../../../src/pages/settings.js" /* webpackChunkName: "component---src-pages-settings-js" */),
  "component---src-pages-users-managment-js": () => import("./../../../src/pages/usersManagment.js" /* webpackChunkName: "component---src-pages-users-managment-js" */)
}

