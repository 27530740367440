import axios from "../../../projectAxios";
import { FetcherResponse } from "../entities/IFetcherFramework";

const URL_TO_FETCH_FROM = "/Usage/Get";

export class videoUsageFramework
{
    constructor(token) {
        this.token = token;
    }

    async FetchFromServer()
    {
        try {
            const resp = await axios.get(
                URL_TO_FETCH_FROM,
                {
                  headers: {
                    Authenticate: this.token,
                  },
                },
                {}
              );

            //console.log("[videoUsageFramework]", resp.data);
            return new FetcherResponse(resp.status, resp.data);
          } catch (error) {
            return new FetcherResponse(error.response.status, []);
          }
    }
};