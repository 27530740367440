class VideoInformation {
    constructor(fileName, masterLink, status, size, statusUpdateDate, uploadDate, isUserNotified) {
      this.fileName = fileName;
      this.masterLink = masterLink;
      this.status = status;
      this.size = size;
      this.statusUpdateDate = statusUpdateDate;
      this.uploadDate = uploadDate;
      this.isUserNotified = isUserNotified;
    }
  }

class Video {
    constructor(id, totalUsage, totalViews, viewsPrecent) {
      this.Id = id;
      this.TotalUsage = totalUsage;
      this.TotalViews = totalViews;
      this.ViewsPrecent = viewsPrecent;
      
    }

    addVideoInformation(videoInfo) {
        this.fileName = videoInfo.fileName;
        this.masterLink = videoInfo.masterLink;
        this.status = videoInfo.status;
        this.size = videoInfo.size;
        this.statusUpdateDate = videoInfo.statusUpdateDate;
        this.uploadDate = videoInfo.uploadDate;
        this.isUserNotified = videoInfo.isUserNotified;    
    }
  }
  
  
  class UsareUsageResponsEntity {
    constructor() {
      this.VideoUsage = [];
      this.isComninedWithStorageInfo = false;
      this.TotalUsage = 0;
      this.TotalPrice = 0;
    
      // This will use later to update the state
      this.cbForResaveUs = null;
    }

    getSortedVideoUsageByUploadDates()
    {
        let sortedList = this.VideoUsage.sort((a, b) =>
        {
            if (a.uploadDate < b.uploadDate)
            {
                return -1;
            }
            if (a.uploadDate > b.uploadDate)
            {
                return 1;
            }
        
            return 0;
        });

        sortedList.reverse();
        return sortedList;
    }

    getSortedVideoUsageByUploadDatesWithStorageInfo()
    {
        const initRes = this.getSortedVideoUsageByUploadDates();
        
        const res = [];
        initRes.forEach(obj => {
            if (obj.fileName !== null && obj.fileName !== undefined)
            {
                res.push(obj);
            }
        });
        return res;
    }

    getSortedVideoUsageByMostViews()
    {
        return this.VideoUsage.sort((a, b) => (a.TotalUsage > b.TotalUsage) ? -1 : 1).slice(0, 6);
    }

    _combainVideoStorageInformationValue(value){
        const vidInfo = new VideoInformation (
            value.fileName,
            value.masterLink ,
            value.status,
            value.size,
            value.statusUpdateDate,
            value.uploadDate,
            value.isUserNotified 
        );

        // Insert the information
        var inserted = false;
        this.VideoUsage.forEach(obj => {
            const key = obj.Id;
            if (value.masterLink === key)
            {
                obj.addVideoInformation(vidInfo);
                inserted = true;
                this.isComninedWithStorageInfo = true;
                return;
            }

            if (key === null)
            {
                // this is a storage only object - we don't have a key for it
                return;
            }

            const splittedKey = key.split("/");
            const elementSplittedKey = value.masterLink.split("/");
            if (splittedKey[2] === elementSplittedKey[2] && splittedKey[1] === elementSplittedKey[1])
            {
                obj.addVideoInformation(vidInfo);
                inserted = true;
                this.isComninedWithStorageInfo = true;
                return;
            }
        });

        // We didn't foud a matching usage and storage -> just add the storage
        if (inserted === false)
        {
            const video = new Video(
                null,
                null,
                null,
                null
              );
              video.addVideoInformation(vidInfo);
              this.VideoUsage.push(video);
        }
    }

    combainVideoStorageInformation(videoStorage)
    {
            // Each Video.Id example
            // /20db9ccd447f48d49d4f62f5e7ddb7e7/0c6979522778484d9a63189ec75670af

            if (Array.isArray(videoStorage))
            {
                // [{"fileName":"test2.mp4","masterLink":"/20db9ccd447f48d49d4f62f5e7ddb7e7/84fca690b9974b12b45b13385c12b0dc/1.m3u8?sig=43A4EA64E01F6469BAAE0F28CA3126","status":1,"size":108712,"statusUpdateDate":"2023-03-01T09:43:02.69599Z","uploadDate":"2023-03-01T09:42:55.447484Z","isUserNotified":null}]
                videoStorage.forEach( value => {
                    this._combainVideoStorageInformationValue(value);
                });

            }else
            {
                for (const [ , value] of Object.entries(videoStorage)) {

                    this._combainVideoStorageInformationValue(value);
                }
            }
        }
  }


  const GetJson = (json) => {
    if (json === null || json === undefined)
    {
        return null;
    }

    if (typeof json === 'string')
    {
        // we got here a json string
        return JSON.parse(json.data);
    }

    // We got here an object without dat field
    if (json.data === undefined || json.data === null)
    {
        return json;
    }

    if (typeof json.data === 'string')
    {
        return JSON.parse(json.data);
    }

    // We have data field that need to be parsed
    return json.data;
  }



// We can get here from different angles
// 1. We have saved userUsage with userStorage integrated init
// 2. We have saved userUsage
// 3. We have json response
// -> we want to make sure we return the object to the main location and that we update the main location
//    when needed with information change

  export const parseJsonOfUsareUsageResponsEntity = (json) => {
    const obj = GetJson(json);
    if (obj === null)
    {
        return null;
    }

    if (obj.TotalPrice !== null && obj.TotalPrice !== undefined &&
        (obj.monthlyUsage === null || obj.monthlyUsage === undefined))
        {
            return loadUsareUsageResponsEntity(obj);
        }


    const result = new UsareUsageResponsEntity();

    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed in JavaScript, so we add 1 to get the correct month
    const currentMonth = `${year}_${month}`;

    for (const [key, value] of Object.entries(obj.monthlyUsage)) {
        if (key !== currentMonth)
        {
            continue;
        }
        result.TotalUsage = value.totalUsage;
        result.TotalPrice = value.totalPrice;
      for (const videoUsage of value.videoUsage) {
        const video = new Video(
          videoUsage.id,
          videoUsage.totalUsage,
          videoUsage.totalViews,
          videoUsage.viewsPrecent
        );
        result.VideoUsage.push(video);
      }
    }
    return result;
  }


  export const loadUsareUsageResponsEntity = (json) => {
    const obj = GetJson(json);
    if (obj === null)
    {
        return null;
    }

    const result = new UsareUsageResponsEntity();

    result.TotalUsage = obj.TotalUsage;
    result.TotalPrice = obj.TotalPrice;

    obj.VideoUsage.forEach(videoUsage => {
        const video = new Video(
            videoUsage.Id,
          videoUsage.TotalUsage,
          videoUsage.TotalViews,
          videoUsage.ViewsPrecent
          );

          // Load VideoInformation as well if we have this information
            if (videoUsage.fileName !== null && videoUsage.fileName !== undefined)
            {
                const vidInfo = new VideoInformation (
                    videoUsage.fileName,
                    videoUsage.masterLink ,
                    videoUsage.status,
                    videoUsage.size,
                    videoUsage.statusUpdateDate,
                    videoUsage.uploadDate,
                    videoUsage.isUserNotified 
                );

                video.addVideoInformation(vidInfo);
            }

          result.VideoUsage.push(video);
    });

    return result;
  }
