export class VideoInformation {
    constructor(fileName, masterLink, status, size, statusUpdateDate, uploadDate, isUserNotified) {
      this.fileName = fileName;
      this.masterLink = masterLink;
      this.status = status;
      this.size = size;
      this.statusUpdateDate = statusUpdateDate;
      this.uploadDate = uploadDate;
      this.isUserNotified = isUserNotified;
    }
}

const GetJson = (json) => {
    if (json === null || json === undefined)
    {
        return null;
    }

    if (typeof json === 'string')
    {
        // we got here a json string
        return JSON.parse(json.data);
    }

    return json;
}

export const parseJsonOfUsareStorage = (json) => {
    const obj = GetJson(json);
    if (obj === null)
    {
        return [null, null];
    }

    // This old total bytes we should to show for the price
    const totalBytesStored = obj.totalBytesStored;
    const resp = [];
    if (obj.videoList === null || obj.videoList === undefined)
    {
        return [null, null];
    }

    if (Array.isArray(obj.videoList))
    {
        obj.videoList.forEach( value => {
            resp.push(new VideoInformation(value.fileName,
                value.masterLink ,
                value.status,
                value.size,
                value.statusUpdateDate,
                value.uploadDate,
                value.isUserNotified));
        });

        return [resp, totalBytesStored];
    }

    return [null, null];
  }