import getMinuteDifference from "../timeUtilities/getMinuteDifference";

const serializedObject = (data) => {
    return JSON.stringify(data)
}

const deserializedObject = (obj) => {
    return JSON.parse(obj);
}


const deleteCache = (localVariableName) =>
{
    if (isBrowser() === false)
    {
        return;
    }

    window.localStorage.removeItem(localVariableName);
};

const saveObject = (obj, localVariableName) => {
    if (isBrowser() === false)
    {
        return;
    }

    const objectWideTime = {
        obj: obj,
        time: Date.now()
    }
    const serializedObj = serializedObject(objectWideTime);
    window.localStorage.setItem(localVariableName, serializedObj);
}

const loadObject = (timeout, revokeAfterTimeout, localVariableName) => {
    if (isBrowser() === false)
    {
        return;
    }

    const serializedObj = window.localStorage.getItem(localVariableName);
    if (serializedObj === null || serializedObj === undefined)
    {
        return null;
    }

    const deserializedObj = deserializedObject(serializedObj);
    if (deserializedObj === null || deserializedObj === undefined)
    {
        return null;
    }

    // We can revoke here our state if the time pass is too big
    const minuteDifferent = getMinuteDifference(Date.now(), deserializedObj.time);
    if (revokeAfterTimeout === true &&
        minuteDifferent > timeout)
    {
        return null
    }

    return deserializedObj.obj;
}

const isBrowser = () => typeof window !== "undefined";


export class Cache {
    constructor(timeout=70, revokeAfterTimeout=true, localVariableName="state") {
        if (isBrowser() === false)
        {
            return;
        }

        this.timeout = timeout;
        this.revokeAfterTimeout = revokeAfterTimeout;
        this.localVariableName = localVariableName;

        this.state = loadObject(this.timeout, this.revokeAfterTimeout, this.localVariableName);
    }

    save(state) {
        // saveObject = (obj, localVariableName)
        saveObject(state, this.localVariableName);
    }

    load()
    {
        // loadObject = (timeout, revokeAfterTimeout, localVariableName)
        return loadObject(this.timeout, this.revokeAfterTimeout, this.localVariableName);
    }

    delete()
    {
        deleteCache(this.localVariableName);
    }

    savePrefix(state, prefix) {
        // saveObject = (obj, localVariableName)
        saveObject(state, `${prefix}_${this.localVariableName}`);
    }

    loadPrefix(prefix)
    {
        // loadObject = (timeout, revokeAfterTimeout, localVariableName)
        return loadObject(this.timeout, this.revokeAfterTimeout, `${prefix}_${this.localVariableName}`);
    }

    deletePrefix(prefix)
    {
        deleteCache(`${prefix}_${this.localVariableName}`);
    }
}
