
const isNullOrUndefined = (obj) => {
    if (obj === null || obj === undefined)
    {
        return true;
    }

    return false;
}

export default isNullOrUndefined;
