import { createSlice } from "@reduxjs/toolkit"
import { Cache } from "../utils/cache/index";
import { parseJsonOfUsareUsageResponsEntity } from "../utils/UsareUsageResponsHandler";
import {videoStorageAndUSageFramework} from  "../services/file_service/frameworks/videoStorageAndUSageFramework";


// New version of fetcher
import { AreUsareUsageAndStorageEntityEqual } from "../services/file_service/entities/VideoStorageAndUsage";

const cache = new Cache();

const tokenTimeoutInMinutes =  60 * 3 + 10;  // on 70, from 5 only 2 get uploaded, so I make it X3 the time
const tokenCache = new Cache(tokenTimeoutInMinutes, true, "tokenState");
const state = cache.state;
const emptyState = {
    isLoggedIn: false,
    needToReload: false,
    token: null,
    storageInformation: null,
    userInformation: null,
    videoInformation: null,
    videoUsageStorage: null,
    serverMetrics: null
};

const isBrowser = () => typeof window !== "undefined";

const GetStoreStateFromAllCaches = () =>
{
    if (isBrowser() === false)
    {
        return emptyState;
    }

    const tokenState = tokenCache.state;
    const cacheState = cache.state;

    // If we have no state - return no state
    if (tokenState === null && cacheState === null)
    {
        return emptyState;
    }

    // We have only token
    if (tokenState !== null && cacheState === null)
    {
        const newObj = JSON.parse(JSON.stringify(emptyState));
        newObj.isLoggedIn = tokenState.isLoggedIn;
        newObj.token = tokenState.token;
        newObj.needToReload = tokenState.needToReload;
        return newObj;
    }

    // We only have cache
    if (tokenState === null && cacheState !== null)
    {
        const newObj = JSON.parse(JSON.stringify(cacheState));
        newObj.isLoggedIn = emptyState.isLoggedIn;
        newObj.token = emptyState.token;
        newObj.needToReload = emptyState.needToReload;
        return newObj;
    }

    // We have a valid cache && a valid token
    if (tokenState !== null && cacheState !== null)
    {
        const newObj = JSON.parse(JSON.stringify(cacheState));
        newObj.isLoggedIn = tokenState.isLoggedIn;
        newObj.token = tokenState.token;
        newObj.needToReload = tokenState.needToReload;
        return newObj;
    }

    // we should never come here
    return emptyState;
}

const GetSavedObj = (state) =>
{
    const localCopy = JSON.parse(JSON.stringify(state));
    localCopy.videoUsageStorage = null;
    return localCopy;
}

const portalSlice = createSlice({
    name: "portal",
    initialState: GetStoreStateFromAllCaches(), // we get our saved state from cache or initial empty one if we have no cache
    reducers: {
        IsTokenExpired: (state, param) =>
        {
            const tokenState = tokenCache.load();
            if (tokenState === null)
            {
                return JSON.parse(JSON.stringify(emptyState));
            }
            
            if (tokenState.isLoggedIn !== state.isLoggedIn ||
                tokenState.token !== tokenState.token ||
                tokenState.needToReload !== tokenState.needToReload)
            {          
                const newFields = {
                    isLoggedIn: tokenState.isLoggedIn,
                    token: tokenState.token,
                    needToReload: tokenState.needToReload,
                };

                return {
                    ...state,
                    ...newFields, // Merge the existing state with the updated fields
                  }
            }
        },
        ClearState: (state, param) => {
            state = {};
            cache.save(GetSavedObj(state));

            // Add delete the token cache
            tokenCache.delete();
        },
        ClearToken: (state, param) => {
            state.token = null;
            state.isLoggedIn = false;
            //cache.save(GetSavedObj(state));

            tokenCache.save(GetSavedObj(state));
        },
        ClearTokenAndCache: (state, param) => {
            // Add delete the token cache
            tokenCache.delete();

            state = emptyState;
            cache.delete();
            const helper = new videoStorageAndUSageFramework(state.token);
            helper.DeleteCache();
        },
        SetToken: (state, param) => {
            state.token = JSON.stringify(param.payload)

            // Create / update our use cases
            // initVideoStorageAndUsageUseCases(state);
            if (state.isLoggedIn === false)
            {
                state.needToReload = true;
            }

            state.isLoggedIn = true
            //cache.save(GetSavedObj(state));
            tokenCache.save(GetSavedObj(state));
        },
        SetServerMetrics: (state, param) => {
            const currentState = state.serverMetrics;
            const newObj = JSON.parse(param.payload);

            // Test if we actually need here a change
            /*if (AreUsareUsageAndStorageEntityEqual(currentState, newObj) === true)
            {
                // There is nothing to do here
                return;
            }*/

            // Create the new object
            state.serverMetrics = newObj

        },
        SetVideoUsageStorage: (state, param) => {
            //console.log("[SetVideoUsageStorage] start");
            const currentState = state.videoUsageStorage;
            const newObj = JSON.parse(param.payload);

            // Test if we actually need here a change
            if (AreUsareUsageAndStorageEntityEqual(currentState, newObj) === true)
            {
                // There is nothing to do here
                return;
            }

            // Create the new object
            //console.log("[SetVideoUsageStorage] before set state");
            state.videoUsageStorage = newObj
            //console.log("[SetVideoUsageStorage] after set state");
            // No need to cache it - we have a class handler that handle this object cache
        },
        SetVideoData: (state, param) => {
            const newVideoObject = {
                data: JSON.stringify(param.payload),
                time: Date.now()
            };

            state.storageInformation = newVideoObject;
            cache.save(GetSavedObj(state));
        },
        SetVideoUsage: (state, param) => {
            // new version for usage -> now we convert it to inner clas with functionality

            const newVideoObject = {
                //data: JSON.stringify(param.payload),
                data: parseJsonOfUsareUsageResponsEntity(param.payload),
                time: Date.now()
            };

            state.videoInformation = newVideoObject;
            cache.save(GetSavedObj(state));
        },
        SetUserInformation: (state, param) => {
            const newVideoObject = {
                data: JSON.stringify(param.payload),
                time: Date.now()
            };

            // Failed update / attempt to delete this field
            if (param.payload === null || param.payload === undefined)
            {
                state.userInformation = null;
            }
            else 
            {
                state.userInformation = newVideoObject;
            }
            
            cache.save(GetSavedObj(state));
        },
        ClearVideoData: (state, param) => {
            state.storageInformation = null;
            cache.save(GetSavedObj(state));
        },
    },
    extraReducers: {
    },
  })


export default portalSlice;
export const {
    SetToken,
    ClearState,
    ClearToken,
    ClearTokenAndCache,
    SetVideoData,
    SetVideoUsage,
    SetUserInformation,
    ClearVideoData,
    SetVideoUsageStorage,
    SetServerMetrics,
    IsTokenExpired
  } = portalSlice.actions;