/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from 'react';
import { Provider } from 'react-redux';
//import { configureStore  } from '@reduxjs/toolkit'
import "./src/global.css";

//import portalSlice from './src/store/store';
import { store } from "./src/store/storeObj";

export const wrapRootElement = ({ element }) => {
    return (
    <Provider store={store}>
        {element}
    </Provider>);
};
  



